import React from "react";
import { Tldraw } from "tldraw";

export const ViewView: React.FC<any> = ({store}) => {

	return (
		<Tldraw
			hideUi
			store={store}
			onMount={(editor) => {
				editor.updateInstanceState({ isReadonly: true });

				// Setting the draw tool, to prevent default select tool. draw won't do anything due to settings above.
				editor.setCurrentTool("draw");
			}}
		/>
	);
};


// dateUtils.ts

export const getWeekNumberAndDay = (): string => {
	const date = new Date();
  
	// Calculate ISO week number
	const janFirst = new Date(date.getFullYear(), 0, 1);
	const days = Math.floor((date.getTime() - janFirst.getTime()) / (24 * 60 * 60 * 1000));
	const weekNumber = Math.ceil((days + ((janFirst.getDay() + 6) % 7)) / 7);
  
	// Get day of the month
	const dayOfMonth = date.getDate();
  
	// Format as "weekNumberdayOfMonth"
	const data = `${weekNumber}${dayOfMonth}`;
	return data;
  };
  
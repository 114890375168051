import React, { useEffect } from "react";
import { TLUiComponents, DefaultMainMenu, useEditor, TldrawUiMenuItem, DefaultMainMenuContent, getSnapshot, loadSnapshot, TldrawUiMenuSubmenu, TldrawUiMenuGroup } from "tldraw";

import "tldraw/tldraw.css";
import { useAppContext } from "../../contexts/appcontext";

const ShareCollabMenuItem: React.FC = () => {

	const appContext = useAppContext();

	return <TldrawUiMenuItem id="share" label="Share with Collaborators" icon="share" readonlyOk onSelect={() => {

		appContext.setDialogTitle("Collaborators");
		appContext.setQRCodeValue(`${appContext.domain}?mode=collab&roomId=${appContext.roomId}`);

	}} />;
};

const ShareViewMenuItem: React.FC = () => {

	const appContext = useAppContext();

	return <TldrawUiMenuItem id="share" label="Share with Viewers" icon="share" readonlyOk onSelect={() => {

		appContext.setDialogTitle("Viewers");
		appContext.setQRCodeValue(`${appContext.domain}?mode=view&roomId=${appContext.roomId}`);

	}} />;
};

const LoadMenuItem: React.FC = () => {

	const appContext = useAppContext();

	const editor = useEditor();

	const openFileDialog = () => {
		if (appContext.fileInputRef?.current) {
			appContext.fileInputRef.current.click();
		}
	};

	useEffect(() => {
		if (appContext.fileInputRef?.current) {

			appContext.fileInputRef.current.onchange = async function (event: any) {

				const file = event.target.files?.[0];

				if (file) {
					const text = await file.text();

					loadSnapshot(editor.store, JSON.parse(text));
				}
			};
		}
	}, [appContext.fileInputRef, editor.store]);

	return <TldrawUiMenuItem id="open-file" label="Open file" icon="file" readonlyOk onSelect={openFileDialog} />;
};

const SaveMenuItem: React.FC = () => {

	const editor = useEditor();

	const saveSnapshotDialog = (ssDoc: string, filename: string): void => {

		const blob = new Blob([ssDoc], { type: "application/json" });

		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = filename;

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	return (
		<TldrawUiMenuItem
			id="save-file"
			label="Save file"
			icon="file"
			readonlyOk
			onSelect={() => {

				const { document, session } = getSnapshot(editor.store);
				
				const jsonSnapShot = JSON.stringify({ document, session });

				const filename = prompt("Enter the filename", "document.tldr");
				
				if (filename) {
					saveSnapshotDialog(jsonSnapShot, filename);
				}
			}}
		/>
	);
};

// Wrapper component to convert TldrawUiMenuSubmenu into a valid JSX component
const SubmenuWrapper: React.FC<{ id: string; label: string; children: React.ReactNode }> = ({
	id,
	label,
	children,
  }) => {
	// Explicitly render the submenu as a JSX element
	return <>{TldrawUiMenuSubmenu({ id, label, children })}</>;
  };

export const admincomp: TLUiComponents = {
	// ContextMenu: null,
	// ActionsMenu: null,
	// HelpMenu: null,
	// ZoomMenu: null,
	MainMenu: () => (
		<DefaultMainMenu>
			<SubmenuWrapper id={"file-choices"} label={"File"}>
				<LoadMenuItem />
				<SaveMenuItem />
			</SubmenuWrapper>
			<SubmenuWrapper id={"share-with-others"} label={"Share"}>
				<ShareCollabMenuItem />
				<ShareViewMenuItem />
			</SubmenuWrapper>
			<DefaultMainMenuContent />
		</DefaultMainMenu>
	),
	// Minimap: null,
	// StylePanel: null,
	// PageMenu: null,
	// NavigationPanel: null,
	// Toolbar: null,
	// KeyboardShortcutsDialog: null,
	// QuickActions: null,
	// HelperButtons: null,
	DebugPanel: null,
	DebugMenu: null,
	// SharePanel: null,
	// MenuPanel: null,
	// TopPanel: null,
	// CursorChatBubble: null,
};

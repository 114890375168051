// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #root {
	height: 100%;
}

/* Quickest way to hide these sections*/
[data-mode="collab"] [data-testid="style.fill"],
[data-mode="collab"] [data-testid="style.dash"] {
	display: none;
}

/* On mobile, the main menu is very squished, this just restores it. */
[data-mode="collab"] .tlui-popover {
	width: auto;
}

/* When only once tool is in the toolbar it looks wierd on a mobile, so we center it so there is not a large white space
 on the right side. Seems like mobile wants at least two tools on the toolbar, desktop mode is fine, its happy with one. */
[data-mode="collab"] .tlui-toolbar__tools.tlui-toolbar__tools__mobile {
	justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;CACC,YAAY;AACb;;AAEA,uCAAuC;AACvC;;CAEC,aAAa;AACd;;AAEA,sEAAsE;AACtE;CACC,WAAW;AACZ;;AAEA;0HAC0H;AAC1H;CACC,uBAAuB;AACxB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\nhtml, body, #root {\n\theight: 100%;\n}\n\n/* Quickest way to hide these sections*/\n[data-mode=\"collab\"] [data-testid=\"style.fill\"],\n[data-mode=\"collab\"] [data-testid=\"style.dash\"] {\n\tdisplay: none;\n}\n\n/* On mobile, the main menu is very squished, this just restores it. */\n[data-mode=\"collab\"] .tlui-popover {\n\twidth: auto;\n}\n\n/* When only once tool is in the toolbar it looks wierd on a mobile, so we center it so there is not a large white space\n on the right side. Seems like mobile wants at least two tools on the toolbar, desktop mode is fine, its happy with one. */\n[data-mode=\"collab\"] .tlui-toolbar__tools.tlui-toolbar__tools__mobile {\n\tjustify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { RemoteTLStoreWithStatus, useSync } from "@tldraw/sync";
import { useEffect } from "react";
import { getWeekNumberAndDay } from "./utils/dateUtils";
import { getUniqueRoomId } from "./utils/miscUtils";
import { multiplayerAssets } from "./utils/multiplayerAssets";
import { Constants } from "./constants";
import { ViewSwitcher } from "./components/ViewSwitcher";
import QRCode from "react-qr-code";
import SimpleDialog from "./components/SimpleDialog";
import { TMode, useAppContext } from "./contexts/appcontext";
import { useSearchParams } from "react-router-dom";
import { DefaultColorThemePalette } from "tldraw";

import "tldraw/tldraw.css";
import "./App.css";

const WORKER_URL = process.env.REACT_APP_WORKER_URL;
const assets = multiplayerAssets(WORKER_URL!);

DefaultColorThemePalette.lightMode.grey.solid = 'white';

function App() {

	const appContext = useAppContext();

	const [searchParams, setSearchParams] = useSearchParams();

	const store: RemoteTLStoreWithStatus = useSync({
		uri: `${WORKER_URL}/connect/${appContext.roomId}`,
		assets: assets,
	});

	const isAuthorized = (password: string): boolean => {

		const expectedPassword = getWeekNumberAndDay();

		return password === expectedPassword;
	};

	useEffect(() => {
		const mode = searchParams.get("mode");
		const password = searchParams.get("password");
		const roomId = searchParams.get("roomId");

		if (mode === Constants.ADMIN) {
			if (isAuthorized(password ?? "")) {
				// Only administrators can create new rooms...

				if (!roomId) {
					// but only if there currently is no room...

					const newRoomId = getUniqueRoomId();
					setSearchParams({ roomId: newRoomId, mode: mode, password: password ?? "" });
					appContext.setRoomId(newRoomId);
				}

				appContext.setMode(mode as TMode);
			}
		} else {
			if (mode === Constants.COLLAB || mode === Constants.VIEW) {
				appContext.setMode(mode as TMode);
			} else {
				// If no mode or unrecognized default to viewer
				appContext.setMode('view');
			}
		}

		if (roomId) {
			// Anyone can load a room

			appContext.setRoomId(roomId);
		}
	}, [appContext, searchParams, setSearchParams]);

	return (
		<div style={{ position: "relative", height: "100%" }}>
			<div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 0 }}>
				<ViewSwitcher store={store} />;
			</div>
			{appContext.qrCodeValue &&
			<div style={{position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 1}}>
				<SimpleDialog>
					<QRCode size={256} value={appContext.qrCodeValue} viewBox={`0 0 256 256`}/>
				</SimpleDialog>
			</div>}
		</div>
);
}

export default App;

import React, { useState } from "react";
import { useAppContext } from "../contexts/appcontext";

interface SimpleDialogProps {
	children: React.ReactNode;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(true);

	const appContext = useAppContext();

	const handleClose = () => {
		setIsOpen(false);
		appContext.setDialogTitle("");
		appContext.setQRCodeValue("");
	};

	if (!isOpen || !appContext.qrCodeValue) return null;

	return (
		<div style={styles.overlay}>
			<div style={styles.dialog}>
				<strong>{ appContext.dialogTitle }</strong>
				<button style={styles.closeButton} onClick={handleClose}>×</button>
				<div style={styles.content}>{children}</div>
			</div>
		</div>
	);
};

const styles = {
	overlay: {
		position: "fixed" as "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	dialog: {
		backgroundColor: "#fff",
		padding: "20px",
		borderRadius: "8px",
		position: "relative" as "relative",
		//width: "300px",
		maxWidth: "80%",
	},
	closeButton: {
		position: "absolute" as "absolute",
		top: "10px",
		right: "10px",
		background: "none",
		border: "none",
		fontSize: "30px",
		cursor: "pointer",
	},
	content: {
		marginTop: "20px",
	},
};

export default SimpleDialog;

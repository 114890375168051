import { Tldraw } from "tldraw";
import React from "react";
import { admincomp } from "../tldrawcomponents/admincomp";

export const AdminView: React.FC<any> = ({ store }) => {
	return (
		<Tldraw
			store={store}
			components={admincomp}
			onMount={(editor) => {
				editor.setCurrentTool("draw");
			}}
		/>
	);
};

import { createContext, useContext, RefObject, useState, useRef } from "react";
import { getDomain } from "../utils/miscUtils";

export type TMode = 'admin' | 'collab' | 'view';

interface IAppContext {

	mode: TMode;
	setMode: (value: TMode) => void;

	dialogTitle: string;
	setDialogTitle: (value: string) => void;

	qrCodeValue: string;
	setQRCodeValue: (value: string) => void;

	roomId: string;
	setRoomId: (value: string) => void;
	
	domain: string;
	setDomain: (value: string) => void;

	fileInputRef: RefObject<HTMLInputElement> | null;
}

const AppContext = createContext<IAppContext | undefined>(undefined);

export const useAppContext = () => {

	const context = useContext(AppContext);

	if (!context) {
		throw new Error("useFileInputContext must be used within a FileInputProvider");
	}

	return context;
};

export const AppContextProvider = ({children} : {children: React.ReactNode})  => {

	const [mode, setMode] = useState<TMode>('view');
	const [qrCodeValue, setQRCodeValue] = useState('');
	const [dialogTitle, setDialogTitle] = useState('');
	const [roomId, setRoomId] = useState('');
	const [domain, setDomain] = useState(getDomain());
	const fileInputRef = useRef<HTMLInputElement>(null);

	return (
		<AppContext.Provider value={{
			mode, setMode,
			qrCodeValue, setQRCodeValue,
			dialogTitle, setDialogTitle,
			roomId, setRoomId,
			domain, setDomain,
			fileInputRef
		}}>
		{children}	
		<input style={{display: "none"}} type="file" accept=".tldr" ref={fileInputRef} />
		</AppContext.Provider>
	);
}

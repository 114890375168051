import React from "react";
import { Tldraw } from "tldraw";
import { collapcomp } from "../tldrawcomponents/collapcomp";

import "tldraw/tldraw.css";

export const CollabView: React.FC<any> = ({store}) => {

	return (
		<Tldraw
			store={store}
			components={collapcomp}
			onMount={(editor) => {
				editor.setCurrentTool("draw");
			}}
		/>
	);
};
import React from "react";
import { Constants } from "../constants";
import { AdminView } from "./views/AdminView";
import { ViewView } from "./views/ViewView";
import { CollabView } from "./views/CollabView";
import { useAppContext } from "../contexts/appcontext";

export const ViewSwitcher: React.FC<any> = ({store}) => {

	const appContext = useAppContext();

	const mode = appContext.mode;

	return (
		<div data-mode={mode} style={{height: "100%"}}>
			{mode === Constants.ADMIN && <AdminView store={store} />}
			{mode === Constants.COLLAB && <CollabView store={store} />}
			{mode === Constants.VIEW && <ViewView store={store} />}
		</div>
	);
};

import { TLUiComponents, DefaultToolbar, DrawToolbarItem, DefaultQuickActions, UndoRedoGroup, HighlightToolbarItem, DefaultStylePanel} from "tldraw";
import "tldraw/tldraw.css";

export const collapcomp: Partial<TLUiComponents> = {
	//ContextMenu: null,
	ActionsMenu: null,
	// HelpMenu: null,
	// ZoomMenu: null,
	MainMenu: null,
	// Minimap: null,
	//StylePanel: null,
	StylePanel: () => (
		<DefaultStylePanel>
			{/* In the end it was just easier to hide these using CSS */}
	  </DefaultStylePanel>
	),
	// PageMenu: null,
	// NavigationPanel: null,
	Toolbar: () => (
		<DefaultToolbar>
			<DrawToolbarItem />
			{/* <HighlightToolbarItem /> */}
		</DefaultToolbar>
	),
	// KeyboardShortcutsDialog: null,
	QuickActions: () => (
		<DefaultQuickActions>
			<UndoRedoGroup />
		</DefaultQuickActions>
	),
	// HelperButtons: null,
	DebugPanel: null,
	DebugMenu: null,
	// SharePanel: null,
	// MenuPanel: null,
	// TopPanel: null,
	// CursorChatBubble: null,
};
